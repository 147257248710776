import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import logo from '../Assets/logo.png';
import "./Navbar.css";
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [appBarColor, setAppBarColor] = useState('white');
    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleNavClick = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth',
            });
        }
        setDrawerOpen(false);
    };

    const handleContactClick = () => {
        navigate('/Contact');
    };

    const handleHomeClick = () => {
        navigate('/');
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setAppBarColor('#E7F2FD');
            } else {
                setAppBarColor('white');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const drawerList = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {['Home', 'Services', 'Hire Us', 'Technology', 'Contact Us'].map((text) => (
                    <ListItem button key={text} onClick={() => {
                        if (text === 'Contact Us') {
                            handleContactClick();
                        } else if (text === 'Home') {
                            handleHomeClick();
                        } else {
                            handleNavClick(text.toLowerCase().replace(/\s/g, ''));
                        }
                    }}>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <div>
            <AppBar position="fixed" sx={{ backgroundColor: appBarColor, color: 'black' }}>
                <Toolbar className='navbar-app'>
                    <img src={logo} alt="logo" className='navbar-logo' style={{ marginRight: 'auto' }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, marginLeft: 'auto', gap: '22px' }}>
                        <Button
                            sx={{
                                fontSize: '18px',
                                textTransform: 'capitalize',
                                fontWeight: '500',
                                position: 'relative',
                                '&:hover': {
                                    '&::after': {
                                        width: '100%',
                                    },
                                },
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '-2px',
                                    left: 0,
                                    width: '0%',
                                    height: '2px',
                                    backgroundColor: 'blue',
                                    transition: 'width 0.3s ease-in-out',
                                },
                                '&.active::after': {
                                    width: '100%',
                                },
                            }}
                            href='/'
                            color="inherit"
                            onClick={() => handleNavClick('home')}
                        >
                            Home
                        </Button>
                        <Button
                            sx={{
                                fontSize: '18px',
                                textTransform: 'capitalize',
                                fontWeight: '500',
                                position: 'relative',
                                '&:hover': {
                                    '&::after': {
                                        width: '100%',
                                    },
                                },
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '-2px',
                                    left: 0,
                                    width: '0%',
                                    height: '2px',
                                    backgroundColor: 'blue',
                                    transition: 'width 0.3s ease-in-out',
                                },
                                '&.active::after': {
                                    width: '100%',
                                },
                            }}
                            color="inherit"
                            onClick={() => handleNavClick('services')}
                        >
                            Services
                        </Button>
                        <Button
                            sx={{
                                fontSize: '18px',
                                textTransform: 'capitalize',
                                fontWeight: '500',
                                position: 'relative',
                                '&:hover': {
                                    '&::after': {
                                        width: '100%',
                                    },
                                },
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '-2px',
                                    left: 0,
                                    width: '0%',
                                    height: '2px',
                                    backgroundColor: 'blue',
                                    transition: 'width 0.3s ease-in-out',
                                },
                                '&.active::after': {
                                    width: '100%',
                                },
                            }}
                            color="inherit"
                            onClick={() => handleNavClick('hireus')}
                        >
                            Hire Us
                        </Button>
                        <Button
                            sx={{
                                fontSize: '18px',
                                textTransform: 'capitalize',
                                fontWeight: '500',
                                position: 'relative',
                                '&:hover': {
                                    '&::after': {
                                        width: '100%',
                                    },
                                },
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '-2px',
                                    left: 0,
                                    width: '0%',
                                    height: '2px',
                                    backgroundColor: 'blue',
                                    transition: 'width 0.3s ease-in-out',
                                },
                                '&.active::after': {
                                    width: '100%',
                                },
                            }}
                            color="inherit"
                            onClick={() => handleNavClick('technology')}
                        >
                            Technology
                        </Button>
                        <Button
                            sx={{
                                fontSize: '18px',
                                textTransform: 'capitalize',
                                fontWeight: '500',
                                position: 'relative',
                                '&:hover': {
                                    '&::after': {
                                        width: '100%',
                                    },
                                },
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: '-2px',
                                    left: 0,
                                    width: '0%',
                                    height: '2px',
                                    backgroundColor: 'blue',
                                    transition: 'width 0.3s ease-in-out',
                                },
                                '&.active::after': {
                                    width: '100%',
                                },
                            }}
                          
                            color="inherit"
                            onClick={handleContactClick}
                        >
                            Contact Us
                        </Button>
                    </Box>

                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {drawerList}
            </Drawer>
        </div>
    );
};

export default Navbar;



