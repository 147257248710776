// import React, { useState } from "react";
// import Footer from "../Footer/Footer";
// import emailjs from '@emailjs/browser'
// import "./ContactUs.css";
// import InformationFillIcon from 'remixicon-react/InformationFillIcon';
// import CommentIcon from 'remixicon-react/Chat1LineIcon';
// import { v4 as uuidv4 } from 'uuid';
// import { db, collection, addDoc } from "../Firebase";
// const ContactUs = () => {
//     const uid = uuidv4()
//     const [formData, setFormData] = useState({
//         name: "",
//         email: "",
//         subject: "",
//         message: "",
//     });
// let  serviceID = "service_znystmo"
// let templeteID = "template_pgb1ij8"
// let publicKey = "Gay-AJ2pBnEP9Ddym"
//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value,
//         });
//     };
//     const [showSuccess, setShowSuccess] = useState(false);
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//          // Handle form submission
//          try {
//             // await addDoc(collection(db, "User Lists"), formData);

//             // // Clear form data
//             // setFormData({
//             //     name: "",
//             //     email: "",
//             //     subject: "",
//             //     message: "",
//             // });

//             // // Show success dialogue
//             // setShowSuccess(true);

//             // // Hide success dialogue after 5 seconds
//             // setTimeout(() => setShowSuccess(false), 5000);

//             emailjs.sendForm(serviceID,
//                 templeteID,
//                 formData,
//                 publicKey
//             )
//         } catch (error) {
//             console.error("Error adding document: ", error);
//         }
//     };

//     return (
//         <>
//         {showSuccess && (
//                 <div className={`success-dialogue ${!showSuccess ? "fade-out" : ""}`}>
//                     <p>Thank you for contacting us! We will get back to you shortly.</p>
//                 </div>
//             )}
//             <div className="breadcrumb-area">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="breadcrumb_box text-center" id="contact-form">
//                                 <h2 className="breadcrumb-title">Connect With Us</h2>
//                                 <h6 className="font-weight--normal mt-4">Uplift your IT strategy with our Expert Solutions</h6>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="site-wrapper-reveal">
//                 <div className="contact-us-section-wrappaer section-space--pt_100 section-space--pb_70">
//                     <div className="container">
//                         <div className="row align-items-center">
//                             <div className="col-lg-6 col-lg-6">
//                                 <div className="conact-us-wrap-one mb-30">
//                                     <h3 className="heading">For any inquiries or<br /> information requests, <br />reach out by <span className="text-color-primary">filling up<br /> the form. </span></h3>
//                                     <div className="sub-heading">Our team will promptly respond to your query & will get back to you shortly.</div>
//                                 </div>
//                             </div>
//                             <div className="col-lg-6 col-lg-6">
//                                 <div className="contact-form-wrap">
//                                     <form onSubmit={handleSubmit}>
//                                         <div className="contact-form">
//                                             <div className="contact-input">
//                                                 <div className="contact-inner">
//                                                     <input 
//                                                         type="text" 
//                                                         name="name" 
//                                                         className="form-control" 
//                                                         placeholder="Name *" 
//                                                         value={formData.name} 
//                                                         onChange={handleChange} 
//                                                         required 
//                                                     />
//                                                 </div>
//                                                 <div className="contact-inner">
//                                                     <input 
//                                                         type="text" 
//                                                         name="email" 
//                                                         className="form-control" 
//                                                         placeholder="Email *" 
//                                                         value={formData.email} 
//                                                         onChange={handleChange} 
//                                                         required 
//                                                     />
//                                                 </div>
//                                             </div>
//                                             <div className="contact-inner">
//                                                 <input 
//                                                     type="text" 
//                                                     name="subject" 
//                                                     className="form-control" 
//                                                     placeholder="Subject *" 
//                                                     value={formData.subject} 
//                                                     onChange={handleChange} 
//                                                     required 
//                                                 />
//                                             </div>
//                                             <div className="contact-inner contact-message">
//                                                 <textarea 
//                                                     name="message" 
//                                                     className="form-control" 
//                                                     placeholder="Please describe what you need." 
//                                                     value={formData.message} 
//                                                     onChange={handleChange} 
//                                                     required 
//                                                 ></textarea>
//                                             </div>
//                                             <div className="submit-btn mt-20">
//                                                 <button className="ht-btn ht-btn-md" type="submit" id="submitButton">Send message</button>
//                                                 <p className="form-messege"></p>
//                                             </div>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="contact-us-info-wrappaer section-space--pb_100">
//                     <div className="container">
//                         <div className="row" style={{ textAlign: "center" }}>
//                             <div className="col-lg-4 col-md-6">
//                                 <div className="conact-info-wrap mt-30">
//                                     <h5 className="heading mb-20 text-color-primary">Office Address</h5>
//                                     <ul className="conact-info__list">
//                                         <p className="homeparagraph">The Galleria, 302, Yogi Chowk Rd,<br /> Nr. Anupam Business Hub, Chikuwadi,<br /> Surat, Gujarat 394105</p>
//                                     </ul>
//                                 </div>
//                             </div>
//                             <div className="col-lg-4 col-md-6">
//                                 <div className="conact-info-wrap mt-30">
//                                     <h5 className="heading mb-20 text-color-primary">Business Enquiry</h5>
//                                     <ul className="conact-info__list">
//                                         <li className="homeparagraph"><a href="mailto:svastsys.tech@gmail.com" className="hover-style-link">svastsys.tech@gmail.com</a></li>
//                                         <li className="homeparagraph"><a href="tel:+91 63521 15372" className="hover-style-link text-black font-weight--bold">+91 63521 15372</a></li>
//                                     </ul>
//                                 </div>
//                             </div>
//                             <div className="col-lg-4 col-md-6">
//                                 <div className="conact-info-wrap mt-30">
//                                     <h5 className="heading mb-20 text-color-primary">HR Enquiry</h5>
//                                     <ul className="conact-info__list">
//                                         <li className="homeparagraph"><a href="mailto:svastsys.tech@gmail.com" className="hover-style-link">svastsys.tech@gmail.com</a></li>
//                                         <li className="homeparagraph"><a href="tel:+91 63521 15372" className="hover-style-link text-black font-weight--bold">+91 63521 15372</a></li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_two">
//                     <div className="container">
//                         <div className="row align-items-center">
//                             <div className="col-xl-8 col-lg-7">
//                                 <div className="cta-content md-text-center">
//                                     <p className="heading resources-text">Take on a journey to success with our diverse <span className="text-color-primary">IT services designed</span> just for you. Our expert brains are ready to bring your vision to life.</p>
//                                 </div>
//                             </div>
//                             <div className="col-xl-4 col-lg-5">
//                                 <div className="cta-button-group--two text-center">
//                                     <a href="#" className="btnc btn--white btn-one"><span className="btn-icon me-2"><CommentIcon className="far fa-comment-alt"></CommentIcon></span> Let's talk</a>
//                                     <a href="#" className="btnc btn--secondary btn-two "><span className="btn-icon me-2"><InformationFillIcon className="fas fa-info-circle"></InformationFillIcon></span> Get info</a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <Footer />
//         </>
//     );
// }

// export default ContactUs;


import React, { useState, useRef } from "react";
import Footer from "../Footer/Footer";
import emailjs from '@emailjs/browser';
import "./ContactUs.css";
import InformationFillIcon from 'remixicon-react/InformationFillIcon';
import CommentIcon from 'remixicon-react/Chat1LineIcon';

const ContactUs = () => {
    const form = useRef();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });

    const serviceID = "service_znystmo";
    const templateID = "template_pgb1ij8";
    const publicKey = "Gay-AJ2pBnEP9Ddym";

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [showSuccess, setShowSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm(serviceID, templateID, form.current, publicKey)
            .then((result) => {
                console.log('Email sent successfully:', result.text);
                setFormData({
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                });
                setShowSuccess(true);
                setTimeout(() => setShowSuccess(false), 5000);
            }, (error) => {
                console.error("Error sending email:", error.text);
                alert('Failed to send message. Please try again later.');
            });
    };

    return (
        <>
            {showSuccess && (
                <div className={`success-dialogue ${!showSuccess ? "fade-out" : ""}`}>
                    <p>Thank you for contacting us! We will get back to you shortly.</p>
                </div>
            )}
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_box text-center" id="contact-form">
                                <h2 className="breadcrumb-title">Connect With Us</h2>
                                <h6 className="font-weight--normal mt-4">Uplift your IT strategy with our Expert Solutions</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-wrapper-reveal">
                <div className="contact-us-section-wrappaer section-space--pt_100 section-space--pb_70">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-lg-6">
                                <div className="conact-us-wrap-one mb-30">
                                    <h3 className="heading">For any inquiries or<br /> information requests, <br />reach out by <span className="text-color-primary">filling up<br /> the form. </span></h3>
                                    <div className="sub-heading">Our team will promptly respond to your query & will get back to you shortly.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-lg-6">
                                <div className="contact-form-wrap">
                                    <form ref={form} onSubmit={handleSubmit}>
                                        <div className="contact-form">
                                            <div className="contact-input">
                                                <div className="contact-inner">
                                                    <input 
                                                        type="text" 
                                                        name="name" 
                                                        className="form-control" 
                                                        placeholder="Name *" 
                                                        value={formData.name} 
                                                        onChange={handleChange} 
                                                        required 
                                                    />
                                                </div>
                                                <div className="contact-inner">
                                                    <input 
                                                        type="email" 
                                                        name="email" 
                                                        className="form-control" 
                                                        placeholder="Email *" 
                                                        value={formData.email} 
                                                        onChange={handleChange} 
                                                        required 
                                                    />
                                                </div>
                                            </div>
                                            <div className="contact-inner">
                                                <input 
                                                    type="text" 
                                                    name="subject" 
                                                    className="form-control" 
                                                    placeholder="Subject *" 
                                                    value={formData.subject} 
                                                    onChange={handleChange} 
                                                    required 
                                                />
                                            </div>
                                            <div className="contact-inner contact-message">
                                                <textarea 
                                                    name="message" 
                                                    className="form-control" 
                                                    placeholder="Please describe what you need." 
                                                    value={formData.message} 
                                                    onChange={handleChange} 
                                                    required 
                                                ></textarea>
                                            </div>
                                            <div className="submit-btn mt-20">
                                                <button className="ht-btn ht-btn-md" type="submit" id="submitButton">Send message</button>
                                                <p className="form-messege"></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-us-info-wrappaer section-space--pb_100">
                    <div className="container">
                        <div className="row" style={{ textAlign: "center" }}>
                            <div className="col-lg-4 col-md-6">
                                <div className="conact-info-wrap mt-30">
                                    <h5 className="heading mb-20 text-color-primary">Office Address</h5>
                                    <ul className="conact-info__list">
                                        <p className="homeparagraph">The Galleria, 302, Yogi Chowk Rd,<br /> Nr. Anupam Business Hub, Chikuwadi,<br /> Surat, Gujarat 394105</p>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="conact-info-wrap mt-30">
                                    <h5 className="heading mb-20 text-color-primary">Business Enquiry</h5>
                                    <ul className="conact-info__list">
                                        <li className="homeparagraph"><a href="mailto:svastsys.tech@gmail.com" className="hover-style-link">svastsys.tech@gmail.com</a></li>
                                        <li className="homeparagraph"><a href="tel:+91 63521 15372" className="hover-style-link text-black font-weight--bold">+91 63521 15372</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="conact-info-wrap mt-30">
                                    <h5 className="heading mb-20 text-color-primary">HR Enquiry</h5>
                                    <ul className="conact-info__list">
                                        <li className="homeparagraph"><a href="mailto:svastsys.tech@gmail.com" className="hover-style-link">svastsys.tech@gmail.com</a></li>
                                        <li className="homeparagraph"><a href="tel:+91 63521 15372" className="hover-style-link text-black font-weight--bold">+91 63521 15372</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_two">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-lg-7">
                                <div className="cta-content md-text-center">
                                    <p className="heading resources-text">Take on a journey to success with our diverse <span className="text-color-primary">IT services designed</span> just for you. Our expert brains are ready to bring your vision to life.</p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <div className="cta-button-group--two text-center">
                                    <a href="#" className="btnc btn--white btn-one"><span className="btn-icon me-2"><CommentIcon className="far fa-comment-alt"></CommentIcon></span> Let's talk</a>
                                    <a href="#" className="btnc btn--secondary btn-two "><span className="btn-icon me-2"><InformationFillIcon className="fas fa-info-circle"></InformationFillIcon></span> Get info</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ContactUs;